<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="push">
              <b-form-group id="input-group-1" label-for="input-1">
                <label>Name <span style="color: red">*</span></label>
                <b-form-input id="input-1" placeholder="Enter Name" v-model="form.name" :class="{
                  'is-invalid': submitted && $v.form.name.$error,
                }"></b-form-input>
                <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">
                  Name is required.
                </div>
              </b-form-group>
              <b-form-group id="input-group-2" label-for="input-2">
                <label>Subject <span style="color: red">*</span></label>
                <b-form-input id="input-2" placeholder="Enter Subject" v-model="form.subject" :class="{
                  'is-invalid': submitted && $v.form.subject.$error,
                }"></b-form-input>
                <div v-if="submitted && !$v.form.subject.required" class="invalid-feedback">
                  Subject is required.
                </div>
              </b-form-group>
              <b-form-group id="input-group-3" label-for="input-3">
                <label>Content <span style="color: red">*</span></label>
                <b-form-textarea id="input-3" rows="3" max-rows="6" v-model="form.content" placeholder="Enter Content"
                  :class="{
                    'is-invalid': submitted && $v.form.content.$error,
                  }">
                </b-form-textarea>
                <div v-if="submitted && !$v.form.content.required" class="invalid-feedback">
                  Content is required.
                </div>
              </b-form-group>
              <b-button type="submit" variant="primary" class="mr-2" @click.prevent="submitData"><span
                  v-if="this.$route.name == 'add-push-notification'">Save Data</span>
                <span v-else>Update Data</span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import notificationPush from "../../../../mixins/ModuleJs/notification-push";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Layout,
    PageHeader,
  },
  mixins: [MixinRequest, notificationPush],
  data() {
    return {
      submitted: false,
      title: "Push",
      items: [
        {
          text: "Back",
          href: "/push-notification",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      name: { required },
      subject: { required },
      content: { required },
    },
  },
};
</script>
